import React from 'react';
import './App.css';
import MyGlobe from './globe';
import { Link } from 'react-router-dom';


function Nodes() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="globe-container">
             <div className="globe-holder">
                <MyGlobe />
              </div>
          </div>
          <div className='space'>
            <Link to="/">
                <button className="button">Home</button>
            </Link>
          </div> 
          </header>
      </div>
    );
  }
  
  export default Nodes;

  
  
  
  
  