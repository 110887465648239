import React from 'react';
import '../../App.css';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import Post1 from '../../data/posts/Hello_World';
import Post2 from '../../data/posts/btc';

const BlogPost = () => {
    const { postId } = useParams();
    let post;
  
    // Decide which post to show based on the postId
    if (postId === 'hello-world') {
      post = Post1;
    } else if (postId === 'btc') {
      post = Post2;
    }
  
    // Logic for other posts
  
    return (
        <div>
        {post ? (
          <div className="App">
          <header className="App-header">
          <Container className='Box'>
            <h1 className="blogtitle">{post.title}</h1>
            <h2 className="blogdate">{post.date}</h2>
            <div className="blog" dangerouslySetInnerHTML={{ __html: post.content }} />
            </Container>
            <Link to="/">
                <button className="button">Home</button>
            </Link>
        </header>
          </div>
        ) : (
          <p>Post not found.</p>
        )}
      </div>
    );
  };
  
  export default BlogPost;