import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Link } from 'react-router-dom';

const phrases = ["compute", "storage", "future"];

function App() {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const typingTimeout = useRef(null);

  useEffect(() => {
    const targetPhrase = phrases[index];

    if (text === targetPhrase) {
      typingTimeout.current = setTimeout(() => setText(t => t.slice(0, -1)), 2000);
    } else if (text.length < targetPhrase.length) {
      typingTimeout.current = setTimeout(() => setText(targetPhrase.slice(0, text.length + 1)), 200);
    }

    return () => clearTimeout(typingTimeout.current);
  }, [text, index]);

  useEffect(() => {
    if (text.length === 0) {
      setIndex((index + 1) % phrases.length);
    }
  }, [text,index]);

  return (
    <div className="App">
      <header className="App-header">
        <p className="default">
          Harnessing the power of the <a href="https://internetcomputer.org/" className="App-link" target="_blank" rel="noopener noreferrer">Internet Computer</a> and <a href="https://ethereum.org/" className="App-link" target="_blank" rel="noopener noreferrer">Ethereum</a> to forge a more sustainable future.</p>
        
        <p className="default">C12 operates nodes in <a href="/Nodes" className="App-link" >Barcelona & Paris</a>. For more info, read our <Link to="/blog" className="App-link">blog</Link>.</p>
        <p className="default">Inquires: <b><a href="mailto:info@c12.space" className="App-link">info@c12.space</a></b></p>
        
        <Link to="/">
          <button className="button">Home</button>
        </Link>
      </header>
      <div className="footer">Carbon Twelve GmbH 2024 &copy;</div>
    </div>
     
  );
}

export default App;

