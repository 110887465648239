import React from 'react';
import './App.css';
import Globe from 'react-globe.gl';


function MyGlobe() {
    const nodes = [
     // { lat: 38.79076334293585, lng: -9.123195632411976, color: 'green', href: "https://dashboard.saturn.tech/stats/eyJmIjp7ImxvY2F0aW9uIjp7ImZpbHRlclR5cGUiOiJ0ZXh0IiwidHlwZSI6ImNvbnRhaW5zIiwiZmlsdGVyIjoibGlzYm9uIn0sImlkIjp7ImZpbHRlclR5cGUiOiJ0ZXh0IiwidHlwZSI6ImNvbnRhaW5zIiwiZmlsdGVyIjoiNmJiMWJiOTItMDA4NS00OGE5LWE5NDQtMGY2OWI5ZWM3YzQwIn19LCJzIjpbXX0="  },  // Lisbon
      { lat: 48.836, lng: 2.588, color: '#009997', href: "https://dashboard.internetcomputer.org/center/pr1"},  // Paris
      { lat: 41.48965800787382, lng: 2.123259996861566, color: 'blue', href: "https://dashboard.internetcomputer.org/center/es1" } //  Barcelona
    ];

    const handlePointClick = (point, event) => {
      window.open(point.href, '_blank');
  };

  
    return (
      <div className='globe-container' style={{ position: 'relative' }}>
        <Globe
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        pointsData={nodes}
        pointAltitude={0.1}
        pointRadius={0.25}
        pointColor={point => point.color}  // Set each point's color based on the node's color property
        backgroundColor='black'
        cameraDistanceRadiusScale={1.9}  // Adjust this value to make the globe smaller
        initialAzimuthalAngle={-Math.PI / 9}  // Adjust the initial azimuthal angle (rotation around the globe)
        initialPolarAngle={Math.PI / 2.5}  // Adjust the initial polar angle (tilt up or down)
        onPointClick={handlePointClick}
        width={800}  // These can help control the size
        height={400}
        />
        </div>
              
    );
  }
  
  export default MyGlobe;
