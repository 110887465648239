import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import './App.css';
import About from "./About";
import Main from "./Main";
import Nodes from "./Nodes";
import BlogList from "./components/blog/BlogList"; 
import BlogPost from "./components/blog/BlogPost";  

function App() {
  return (
    <div className="App">
       <Navbar expand="lg" variant="dark" bg="light" sticky="top" className="NavBar">
        <Nav className="flex-grow-1">
          <Link to="/" className="hamburger">
           <FontAwesomeIcon color="black" icon={faBars} className="hamburger" />
          </Link> 
        </Nav>
      </Navbar>
       <div className="me-auto">
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/nodes" element={<Nodes />} />
          <Route path="/blog" element={<BlogList />} />       
          <Route path="/blog/:postId" element={<BlogPost />} /> 
        </Routes>
      </div>
    </div>
  );
}

export default App;
