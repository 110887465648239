const post1 = {
    title: "Carbon Twelve: A Confluence of Elemental Strength and Cosmic Vision",
    date: "December 20th, 2023",
    content: `
        <h2>Hello World!</h2>

        <p><a href="https://en.wikipedia.org/wiki/Carbon-12" className="App-link" target="_blank" rel="noopener noreferrer">Carbon-12</a>, a stable isotope at the core of all organic life, is a marvel of nature's balance. Its ubiquitous presence and stability are mirrored in the ethos of C12, where balance, resilience, and sustainability form the bedrock of our mission.</p>

        <p>Named after this life-sustaining isotope, Carbon-12, C12 isn't just another tech company. It's a testament to the power of perspective, resilience, and the transformative nature of seeing our world as an interconnected whole.</p>

        <p>We harness the <a href="https://internetcomputer.org/" className="App-link" target="_blank" rel="noopener noreferrer">Internet Computer</a> and <a href="https://filecoin.io" className="App-link" target="_blank" rel="noopener noreferrer">Filecoin</a> networks, aiming to bring with the same durability and adaptability into the digital realm that Carbon-12 brings to life on Earth.</p>

        <h3>The Overview Effect</h3>
        <p>When astronauts view Earth from space, they experience the <a href="https://en.wikipedia.org/wiki/Overview_effect" className="App-link" target="_blank" rel="noopener noreferrer">Overview Effect</a> — a profound realization of our planet's fragility and interconnectedness. This awe-inspiring perspective is at the heart of C12's philosophy. We envision a world where technology transcends its role as a mere tool and becomes a unifying force.</p>

        <h3>Decentralization</h3>
        <p>Carbon-12's six protons and six neutrons symbolize natural harmony, a concept C12 embodies through decentralization. Our decentralized cloud solutions, essential for secure and efficient enterprise-grade infrastructure, reflect the global tapestry of our interconnected world.</p>

        <h3>Renewable Energy</h3>
        <p>C12's pledge to power all <a href="/Nodes" className="App-link" >nodes</a> with 100% renewable energy resonates with the cyclical nature of Carbon-12 in supporting life. Our efforts in making verifiable environmental claims go beyond compliance; they are a manifestation of our dedication to a sustainable digital infrastructure.</p>

        <h3>Our Vision</h3>
        <p>Blending technological prowess with ecological awareness, our aim is to create a future where digital progress and environmental stewardship coexist in symbiosis, much like Carbon-12's role in the natural balance.</p>

        <p>C12 is not merely participating in the technological revolution; we are redefining it with a profound understanding of our role in the larger environmental and energy play. As we introduce ourselves to the world, we invite you to join us in this journey of innovation, responsibility, and sustainability, reminding us all of our shared duty to this incredible planet we inhabit.</p>

    <p>Welcome to the world of Carbon Twelve — where technology and ecology converge, creating a future as resilient and balanced as the isotope that inspires us.</p>
`,
};

export default post1;