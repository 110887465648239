import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import Post1 from '../../data/posts/Hello_World';
import Post2 from '../../data/posts/btc';

const BlogList = () => {
    return (
      <div className="App">
      <header className="App-header">
      <h1 className="default">C12 Posts</h1>
      <Container className='Box'>
        <ul>
          <li>
          <b>{Post2.date}: <Link to="/blog/btc" className="App-link">Energetic Essence</Link></b>
          </li>
          <li>
          <b>{Post1.date}: <Link to="/blog/hello-world" className="App-link">raison d'être</Link></b>
          </li>
        </ul>
        </Container>
          <Link to="/">
              <button className="button">Home</button>
          </Link>
      </header>
      </div>
    );
  };
  
  export default BlogList;
